import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { Label, LabelSmall } from 'components/form/Label';
import { SelectInput } from 'components/form/SelectInput';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { Paragraph } from 'components/typography';
import { ChangeEvent, FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterStore } from 'state/filterStore';
import { Project } from 'types/project';
import { ProjectSensor } from 'graphql/generated';
import { Ashrae2019Average, Ashrae2019Control, Ashrae2019View } from 'types/view/ashrae2019View';
import { isNonEmptyString } from 'utils/validation';
import shallow from 'zustand/shallow';
import { NoSensors } from './NoSensors';
import { ToggleButton } from 'components/form/ToggleButton';
import { LocationSelect } from 'components/form/LocationSelect';
import { useProjectSensorLocation } from 'hooks/useProjectSensorLocation';
import { Location } from 'types/location';
import { Measurement } from 'types/sensor';
import { ViewType } from 'types';
import { useProjectSensorLocationState } from 'hooks/useProjectSensorLocationState';

// move me to a separate file
function parseNumberField(e: ChangeEvent<HTMLInputElement>) {
  const result = e.target.valueAsNumber;
  return isNaN(result) ? undefined : result;
}

type Props = {
  view?: Ashrae2019View;
  projectId: Project['id'];
  loading: boolean;
  onSave: (
    name: string,
    control: Ashrae2019Control,
    average: Ashrae2019Average,
    projectSensorIds: ProjectSensor['id'][],
    autoAddNewDevices?: boolean,
  ) => void;
  saveLabel?: React.ReactNode;
  onClose: () => void;
};

export const AshraeViewForm: FC<Props> = ({ view, projectId, loading, onSave, saveLabel = 'Save', onClose }) => {
  const { t } = useTranslation();
  const [control, setControl] = useState<Ashrae2019Control>(Ashrae2019Control.A1);
  const [temp, setTemp] = useState<number>();
  const [hum, setHum] = useState<number>();

  const {
    name,
    setName,
    autoAddNewDevices,
    selectedLocations,
    setAutoAddNewDevices,
    projectSensorsBySelectedLocations,
    setSelectedLocations,
    dynamicLocationsByProjectSensors,
  } = useProjectSensorLocationState({ view, projectId, viewType: ViewType.ASHRAE });

  const {
    loadingProjectSensors,
    noSensorsYet,
    setOffset,
    onAddLocations,
    onRemoveLocation,
    loadLocationOptions,
    handleSearch,
  } = useProjectSensorLocation({ projectId });

  useEffect(() => {
    if (view !== undefined) {
      view.control && setControl(view.control);
      view.average?.temp && setTemp(view.average.temp);
      view.average?.hum && setHum(view.average.hum);
    }
  }, [view]);

  const canUseTemp = useMemo(
    () => [Ashrae2019Control.AA, Ashrae2019Control.A1, Ashrae2019Control.A2, Ashrae2019Control.B].includes(control),
    [control],
  );

  const canUseHum = useMemo(() => [Ashrae2019Control.A1, Ashrae2019Control.B].includes(control), [control]);

  const formValid = useMemo(() => {
    if (name && projectSensorsBySelectedLocations)
      return (
        name.length <= 255 && name.length >= 1 && control !== undefined && projectSensorsBySelectedLocations.length > 0
      );
  }, [name, control, projectSensorsBySelectedLocations]);

  const [clearFilters] = useFilterStore((state) => [state.projectSensorsForm.clear], shallow);

  const save = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      clearFilters();
      e.preventDefault();
      const average = { temp: canUseTemp ? temp : undefined, hum: canUseHum ? hum : undefined };
      const projectSensorIds = projectSensorsBySelectedLocations
        ?.filter(
          (sensor) =>
            sensor?.sensor?.device?.locations.some((deviceLocation) =>
              selectedLocations.some((location) => location.id === deviceLocation.location.id),
            ) &&
            [Measurement.HUMIDITY, Measurement.TEMPERATURE].includes((sensor.sensor?.measurement as Measurement) ?? ''),
        )
        .map((sensor) => sensor.id);
      if (projectSensorIds && projectSensorIds.length > 0) {
        onSave(name, control, average, projectSensorIds, autoAddNewDevices);
      }
    },
    [
      clearFilters,
      canUseTemp,
      temp,
      canUseHum,
      hum,
      onSave,
      name,
      control,
      autoAddNewDevices,
      projectSensorsBySelectedLocations,
      selectedLocations,
    ],
  );

  const controlTypeOptions = useMemo(() => {
    return Object.keys(Ashrae2019Control).map((key) => {
      const value = Ashrae2019Control[key as keyof typeof Ashrae2019Control];
      return { value: key, label: value };
    });
  }, []);

  const loadOptions = useCallback(
    () => loadLocationOptions(dynamicLocationsByProjectSensors),
    [loadLocationOptions, dynamicLocationsByProjectSensors],
  );

  return (
    <Flex height="100%">
      {!loadingProjectSensors && noSensorsYet ? (
        <NoSensors projectId={projectId} onClick={onClose} />
      ) : (
        <Form onSubmit={save} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Body>
            <Input
              validate={isNonEmptyString}
              label={t('Name')}
              type="text"
              value={name ?? ''}
              marginProps={{ mb: '1.5rem' }}
              placeholder={t('placeholders.Enter the view name')}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Flex mb="1.5rem" flexDirection="column">
              <Label mb="0.25rem">{t('ASHRAE control type')}*</Label>
              <Flex width="16.5rem">
                <SelectInput
                  options={controlTypeOptions}
                  onChange={(value) => setControl(value as Ashrae2019Control)}
                  value={control && { value: control, label: Ashrae2019Control[control] }}
                  placeholder={t('actions.Select a sampling interval')}
                />
              </Flex>
              <Paragraph fontSize={['0.75rem', '0.875rem']}>
                {control} - {t(`ashrae_type_description.${control}`)}
              </Paragraph>
            </Flex>
            {(canUseTemp || canUseHum) && (
              <Flex flexDirection="column" mb="1.5rem">
                <Label mb="0.25rem">{t('Yearly averages (mean)')}</Label>
                <LabelSmall>
                  {t(
                    'This additional information allows us to calculate the allowed variance in temperature and humidity according to ASHRAE 2019 standards. When you have been collecting data for over a year, this will be calculated automatically',
                  )}
                </LabelSmall>
                <Flex mb="0.5rem" gap="1.5rem">
                  {canUseTemp && (
                    <Flex gap="0.25rem" alignItems="center">
                      <Input
                        validate={isNonEmptyString}
                        type="number"
                        value={temp ?? ''}
                        css={{
                          flexShrink: 1,
                        }}
                        inputCss={{
                          maxWidth: '8.75rem',
                        }}
                        placeholder={t('placeholders.temperature').toLowerCase()}
                        onChange={(e) => setTemp(parseNumberField(e))}
                      />
                      <Paragraph fontSize={['0.75rem', '0.875rem']}>C</Paragraph>
                    </Flex>
                  )}
                  {canUseHum && (
                    <Flex gap="0.25rem" alignItems="center">
                      <Input
                        validate={isNonEmptyString}
                        type="number"
                        value={hum ?? ''}
                        inputCss={{
                          maxWidth: '8.75rem',
                        }}
                        placeholder={t('placeholders.relative humidity')}
                        onChange={(e) => setHum(parseNumberField(e))}
                      />
                      <Paragraph fontSize={['0.75rem', '0.875rem']}>%</Paragraph>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )}
            <Flex mb="1.5rem" flexDirection="column">
              <Label>{t('Auto add new devices')}</Label>
              <LabelSmall>{t('When you create a device will be automatically added to this view')}</LabelSmall>
              <ToggleButton value={autoAddNewDevices} onChange={() => setAutoAddNewDevices(!autoAddNewDevices)} />
            </Flex>
            <LocationSelect
              label={`${t('Add measurements by location')}*`}
              selected={selectedLocations}
              loading={loadingProjectSensors}
              onChange={(locations) => onAddLocations(locations as Location[], setSelectedLocations, selectedLocations)}
              loadOptions={loadOptions}
              onRemoveItem={(locations) => onRemoveLocation(locations, setSelectedLocations, selectedLocations)}
              onInputChange={handleSearch}
              onMenuClose={() => setOffset(0)}
            />
          </Body>
          <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
            <Button smallPad onClick={onClose} variant="plain">
              {t('actions.Cancel')}
            </Button>
            <Button smallPad type="submit" loading={loading} disabled={!formValid}>
              {saveLabel}
            </Button>
          </DrawerFooter>
        </Form>
      )}
    </Flex>
  );
};
