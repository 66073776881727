import { User } from 'graphql/generated';
import { useQryMe } from 'graphql/query/useQryMe';
import { createContext, FC } from 'react';

export const UserContext = createContext<[user: User | undefined, loading: boolean]>([undefined, false]);

export const UserProvider: FC = (props) => {
  const [{ data, fetching }] = useQryMe({
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
    createdAt: true,
    language: true,
  });

  return <UserContext.Provider value={[data, fetching]} {...props} />;
};
