import { FC, useEffect, useState, useCallback, useContext } from 'react';
import { H4, Text } from 'components/typography';
import { useTranslation, Trans } from 'react-i18next';
import { Table } from 'components/properties/Table';
import { Box } from 'components/layout/Box';
import { Container, Content } from 'components/layout';
import { Header } from 'components/layout/Header';
import { useHistory } from 'hooks/useHistoryEnhanced';
import { useQryProjectSensorsByRule, useQryRule, ProjectSensorWithRule } from 'graphql/generated';
import { useParams } from 'react-router-dom';
import { MdDeleteOutline } from 'react-icons/md';
import { Dialog } from 'components/Dialog';
import { useDeleteRule } from 'graphql/mutation/useDeleteRule';
import { ProjectContext } from 'context/ProjectContext';

const RuleProjectSensorsDetail: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { ruleId } = useParams<{ ruleId: string }>();
  const [{ data: rule }] = useQryRule({ name: true }, { uuid: ruleId }, {});
  const [{ data: sensors, fetching: loading }, refetchSensors] = useQryProjectSensorsByRule(
    {
      id: true,
      name: true,
      unit: true,
      battery: true,
      sensorId: true,
      measurement: true,
      updatedAt: true,
      ruleId: true,
      deviceId: true,
    },
    {
      data: {
        ruleId: ruleId ?? '',
      },
    },
    { pause: !ruleId },
  );

  const [sensorList, setSensorList] = useState<ProjectSensorWithRule[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [sensorToDelete, setSensorToDelete] = useState<ProjectSensorWithRule | null>(null);
  const [, deleteRule] = useDeleteRule();

  useEffect(() => {
    if (sensors && sensorList.length === 0) {
      setSensorList(sensors ?? []);
    }
  }, [sensors, sensorList]);

  const handleDelete = (sensor: ProjectSensorWithRule) => {
    setSensorToDelete(sensor);
    setShowDeleteDialog(true);
  };

  const confirmDelete = useCallback(async () => {
    if (sensorToDelete) {
      try {
        deleteRule({ ruleId: sensorToDelete.ruleId });
        setShowDeleteDialog(false);
        setSensorToDelete(null);
        await refetchSensors();
        setSensorList(sensorList.filter((sensor) => sensor.id !== sensorToDelete.id));
      } catch (error) {
        console.error(`Error deleting sensor: ${error}`);
      }
    }
  }, [sensorToDelete, history, ruleId, refetchSensors, sensorList]);
  const { project } = useContext(ProjectContext);

  return (
    <Container>
      <Header
        title={rule?.name}
        onClickBack={() => {
          history.push(`/projects/${project?.id}/notifications/rules`);
        }}
      />
      <Content loading={loading}>
        <Box>
          <H4>{t('Measurements')}</H4>
          <Table
            header={{
              cells: [{ title: t('Measurement') }, { title: t('Unit') }, { title: t('Type') }],
            }}
            body={{
              label: t('No measurements'),
              emptyState: sensorList.length === 0,
              rows: sensorList
                ? sensorList.map((sensor) => ({
                    actions: [
                      {
                        text: t('actions.Delete'),
                        onClick: () => handleDelete(sensor),
                        icon: MdDeleteOutline,
                      },
                    ],
                    properties: [
                      { type: 'static', value: sensor.name },
                      {
                        type: 'static',
                        value: sensor.unit,
                      },
                      {
                        type: 'static',
                        value: sensor.measurement,
                      },
                    ],
                  }))
                : [],
            }}
          />
        </Box>
      </Content>
      {showDeleteDialog && (
        <Dialog
          title={t('actions.Confirm Delete')}
          description={
            <Text>
              <Trans
                values={{ ruleName: rule?.name }}
                i18nKey="confirmation_messages.Are you sure you want to delete <1>{{ruleName}}</1>?"
                components={{ 1: <strong /> }}
              />
            </Text>
          }
          maxWidth="640px"
          onCancel={() => setShowDeleteDialog(false)}
          onConfirm={confirmDelete}
        />
      )}
    </Container>
  );
};

export default RuleProjectSensorsDetail;
