import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { FC, FormEvent, useCallback } from 'react';
import { useFilterStore } from 'state/filterStore';
import { Project } from 'types/project';
import { Sensor } from 'types/sensor';
import { isNonEmptyString } from 'utils/validation';
import shallow from 'zustand/shallow';
import { View, ProjectSensor } from 'graphql/generated';
import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { MdDeleteOutline } from 'react-icons/md';
import { colors } from 'theme/colors';
import { Flex } from 'components/layout/Flex';
import { NoSensors } from './NoSensors';
import { Loader } from 'components/loader/loader';
import { Label, LabelSmall } from 'components/form/Label';
import { ToggleButton } from 'components/form/ToggleButton';
import { LocationSelect } from 'components/form/LocationSelect';
import { useProjectSensorLocation } from 'hooks/useProjectSensorLocation';
import { ViewType } from 'types';
import { Location } from 'types/location';
import { Measurement } from '@charphq/types';
import { useProjectSensorLocationState } from 'hooks/useProjectSensorLocationState';

export type LightView = Omit<View, 'sensors' | 'projectId'> & { sensors: ProjectSensor[] };

type Props = {
  view?: LightView;
  projectId: Project['id'];
  loading: boolean;
  onSave: (viewName: View['name'], sensorIds: Sensor['id'][], autoAddNewDevices?: boolean) => void;
  saveLabel?: React.ReactNode;
  onClose: () => void;
  onClickDelete?: () => void;
};

export const CumulativeLightViewForm: FC<Props> = ({
  view,
  projectId,
  loading,
  onSave,
  saveLabel = 'Save',
  onClose,
  onClickDelete,
}) => {
  const { t } = useTranslation();

  const {
    name,
    setName,
    autoAddNewDevices,
    selectedLocations,
    setAutoAddNewDevices,
    projectSensorsBySelectedLocations,
    setSelectedLocations,
    dynamicLocationsByProjectSensors,
  } = useProjectSensorLocationState({
    view,
    projectId,
    viewType: ViewType.CUMULATIVE_SUM_LIGHT_HOURS,
  });

  const {
    loadingProjectSensors,
    noSensorsYet,
    setOffset,
    onAddLocations,
    onRemoveLocation,
    handleSearch,
    loadLocationOptions,
  } = useProjectSensorLocation({ projectId });

  const [clearFilters] = useFilterStore((state) => [state.projectSensorsForm.clear], shallow);

  const save = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      clearFilters();
      e.preventDefault();
      const projectSensorIds = projectSensorsBySelectedLocations
        ?.filter(
          (sensor) =>
            sensor?.sensor?.device?.locations.some((deviceLocation) =>
              selectedLocations.some((location) => location.id === deviceLocation.location.id),
            ) && [Measurement.LIGHT].includes((sensor.sensor?.measurement as Measurement) ?? ''),
        )
        .map((sensor) => sensor.id);
      if (projectSensorIds && projectSensorIds.length > 0) {
        onSave(name, projectSensorIds, autoAddNewDevices);
      }
    },
    [clearFilters, projectSensorsBySelectedLocations, selectedLocations, onSave, name, autoAddNewDevices],
  );

  const loadOptions = useCallback(
    () => loadLocationOptions(dynamicLocationsByProjectSensors),
    [loadLocationOptions, dynamicLocationsByProjectSensors],
  );

  return (
    <Flex height="100%">
      {!loadingProjectSensors && noSensorsYet ? (
        <NoSensors
          projectId={projectId}
          onClick={onClose}
          message="This project has no devices with light measurements added to it yet. Please add them first"
        />
      ) : (
        <Form onSubmit={save} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {loading ? (
            <Loader />
          ) : (
            <Body>
              <Input
                width="16.5rem"
                validate={isNonEmptyString}
                label={t('Name')}
                type="text"
                name="monitorName"
                value={name}
                marginProps={{ mb: '1.5rem' }}
                placeholder={t('placeholders.Enter the view name')}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Flex mb="1.5rem" flexDirection="column">
                <Label>{t('Auto add new devices')}</Label>
                <LabelSmall>{t('When you create a device will be automatically added to this view')}</LabelSmall>
                <ToggleButton value={autoAddNewDevices} onChange={() => setAutoAddNewDevices(!autoAddNewDevices)} />
              </Flex>
              <LocationSelect
                label={`${t('Add measurements by location')}*`}
                selected={selectedLocations}
                loading={loadingProjectSensors}
                onChange={(locations) =>
                  onAddLocations(locations as Location[], setSelectedLocations, selectedLocations)
                }
                loadOptions={loadOptions}
                onRemoveItem={(locations) => onRemoveLocation(locations, setSelectedLocations, selectedLocations)}
                onInputChange={handleSearch}
                onMenuClose={() => setOffset(0)}
              />
            </Body>
          )}

          <DrawerFooter style={{ justifyContent: onClickDelete ? 'space-between' : 'flex-end' }}>
            {onClickDelete && (
              <Button
                smallPad
                iconLeft={MdDeleteOutline}
                variant="plain"
                color={colors.error[700]}
                onClick={onClickDelete}
              >
                {t('actions.Remove view')}
              </Button>
            )}
            <Flex gap="1.5rem">
              <Button smallPad onClick={onClose} variant="plain">
                {t('actions.Cancel')}
              </Button>
              <Button
                smallPad
                type="submit"
                loading={loading}
                disabled={!isNonEmptyString(name) || projectSensorsBySelectedLocations?.length === 0}
              >
                {saveLabel}
              </Button>
            </Flex>
          </DrawerFooter>
        </Form>
      )}
    </Flex>
  );
};
