import { Label } from 'components/form/Label';
import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { Text } from 'components/typography';
import { DrawerComponentProps } from 'context/DrawerContext';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { ConnectionType, connectionTypes } from 'types/device';
import { AddCharpDevice } from './AddCharpDevice';
import { AddStandaloneDevice } from './AddStandaloneDevice';
import { useTranslation } from 'react-i18next';
import { SelectInput } from 'components/form/SelectInput';
import { Flex } from 'components/layout/Flex';
import { ObjectPropertyValue } from 'components/properties/ObjectProperty';
import { useQryGetAvailableLocations, useQryOrganization } from 'graphql/generated';
import { useAddLocation } from 'graphql/mutation/useAddLocation';
import { useEditDeviceLocation } from 'graphql/mutation/useEditDeviceLocation';
import { useSnackbar } from 'components/Snackbar';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { AuthContext } from 'context/AuthContext';

type Props = DrawerComponentProps & {
  isGateway?: boolean;
  externalTag?: string;
};

export const AddDeviceDrawer: FC<Props> = ({ requestClose, isGateway = false, externalTag }) => {
  const { t } = useTranslation();
  const [connectionType, setConnectionType] = useState<ConnectionType>(ConnectionType.CHARP);
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);

  const connectionOptions = useMemo(
    () =>
      Object.keys(connectionTypes).map((type) => ({
        label: connectionTypes[type as keyof typeof connectionTypes] ?? '',
        value: type,
      })),
    [],
  );

  const [, addLocation] = useAddLocation();
  const [, editDeviceLocation] = useEditDeviceLocation();
  const [{ data: org }] = useQryOrganization({ id: true, name: true }, {});

  const [{ data: availableLocations }] = useQryGetAvailableLocations(
    {
      id: true,
      name: true,
    },
    {
      organizationId: org?.id ?? '',
    },
    { pause: !org },
  );

  const locationOptions = useMemo(
    () =>
      availableLocations?.map((location) => ({
        label: location.name,
        value: location.id,
      })) ?? [],
    [availableLocations],
  );

  const onCreateLocation = useCallback(
    async (name: string): Promise<ObjectPropertyValue | undefined> => {
      if (!org) {
        return;
      }
      const newLocation = await addLocation({
        data: {
          name,
        },
        organizationId: org.id,
      });
      if (newLocation.data) {
        snackbar.addAlert(
          t('Created new location: {{location}}', { location: newLocation.data.addLocation.name }),
          Severity.SUCCESS,
        );
        if (newLocation.data.addLocation) {
          const { id, name } = newLocation.data.addLocation;
          return { value: id, label: name, id };
        }
      }
      if (newLocation.error) {
        dispatchErrors(snackbar, newLocation.error, authContext, t);
        return null;
      }
    },
    [addLocation, authContext, org, snackbar, t],
  );

  return (
    <DrawerLayout title={t('actions.Add a new device')} onClose={requestClose}>
      <Flex flexDirection="column" padding="1.5rem" paddingBottom={0}>
        <Label>{t('The connection')}*</Label>
        <Text variant="tiny" mb="0.25rem">
          {t('descriptions.The connection defines how data from your device will enter Charp')}
        </Text>
        <SelectInput
          options={connectionOptions}
          onChange={(value) => setConnectionType(value as ConnectionType)}
          value={{
            value: connectionType,
            label: connectionTypes[connectionType as keyof typeof connectionTypes] ?? '',
          }}
          placeholder={t('actions.Select a connection')}
          width="16.5rem"
        />
      </Flex>

      {connectionType === ConnectionType.CHARP && (
        <AddCharpDevice
          isGateway={isGateway}
          externalXtelTag={externalTag}
          requestClose={requestClose}
          editDeviceLocation={editDeviceLocation}
          locationOptions={locationOptions}
          locations={availableLocations ?? []}
          onCreateLocation={onCreateLocation}
        />
      )}
      {connectionType === ConnectionType.STANDALONE && (
        <AddStandaloneDevice
          isGateway={isGateway}
          requestClose={requestClose}
          editDeviceLocation={editDeviceLocation}
          locationOptions={locationOptions}
          locations={availableLocations ?? []}
          onCreateLocation={onCreateLocation}
        />
      )}
    </DrawerLayout>
  );
};
