import { SampleTime } from '@charphq/types';
import { DefaultQueryResponse } from 'graphql/query/default';
import { LinkedPeriod } from 'types/period';
import { MonitorView, ProjectSensor } from 'graphql/generated';
import { useMutation } from 'urql';

const ADD_MONITOR_VIEW_KEY = 'addMonitorView';

const ADD_MONITOR_VIEW = `
mutation ($projectId: String!, $data: AddMonitorViewInput!) {
  ${ADD_MONITOR_VIEW_KEY}(uuid: $projectId, data: $data) {
    id
    name
    type
    sampleTime
    yDomain {
      min
      max
      measurement
    }
    period {
      id
      start
      end
      updatedAt
      createdAt
    }
    sensors {
      id
      name
      unit
      location {
        id
        name
      }
      sensorId
      measurement
      updatedAt
    }
    updatedAt
  }
}
`;

type MutVarAddMonitorView = {
  projectId: string;
  data: {
    name: string;
    period?: {
      start: string;
      end?: string | null;
    };
    sampleTime?: SampleTime;
    yDomain?: {
      min?: number | null;
      max?: number | null;
      measurement?: string | null;
    }[];
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
  };
};
type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'deviceId' | 'measurement' | 'updatedAt'
>;
type MutPeriod = Pick<LinkedPeriod, 'id' | 'start' | 'end' | 'updatedAt' | 'createdAt'>;

type MutResAddMonitorView = DefaultQueryResponse<
  typeof ADD_MONITOR_VIEW_KEY,
  Pick<MonitorView, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId' | 'sampleTime'> & {
    sensors: MutProjectSensor[];
    period?: MutPeriod;
    yDomain: {
      min?: number | null;
      max?: number | null;
      measurement?: string | null;
    }[];
  }
>;

export const useAddMonitorView = () => {
  return useMutation<MutResAddMonitorView, MutVarAddMonitorView>(ADD_MONITOR_VIEW);
};
