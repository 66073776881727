import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { DrawerComponentProps } from 'context/DrawerContext';
import { ProjectSensorWithRule, useQryProject, useQryProjectSensorsByRule, useQryRule } from 'graphql/generated';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { useEffect, useMemo, useState, VFC } from 'react';
import { RuleForm } from './RuleForm';
import { useTranslation } from 'react-i18next';
import { Loader } from 'components/loader/loader';
import { isEmpty } from 'lodash';

type Props = DrawerComponentProps & {
  alertId: string;
  ruleId?: string;
  projectId: string;
};

export const AlertRuleDrawer: VFC<Props> = ({ requestClose, alertId, ruleId, projectId }) => {
  const { t } = useTranslation();

  const [{ data: rule, fetching: loadingRule }] = useQryRule(
    {
      id: true,
      name: true,
      downsample: true,
      every: true,
      threshold: true,
      optThreshold: true,
      statistic: true,
      optStatistic: true,
      timeWindow: true,
      operator: true,
      comments: true,
      updatedAt: true,
    },
    { uuid: ruleId ?? '' },
    { pause: !ruleId },
  );

  const [{ data: project, fetching: loadingSensors }] = useQryProject(
    {
      id: true,
      name: true,
      views: {
        id: true,
        name: true,
        projectId: true,
        type: true,
        updatedAt: true,
        sensors: {
          id: true,
          unit: true,
          name: true,
          location: {
            id: true,
            name: true,
            createdAt: true,
            organization: {
              id: true,
              name: true,
              createdAt: true,
              email: true,
              language: true,
            },
            updatedAt: true,
          },
          measurement: true,
          deviceId: true,
          sensorId: true,
          updatedAt: true,
        },
        autoAddNewDevices: true,
      },
      sensors: {
        id: true,
        name: true,
        unit: true,
        value: {
          value: true,
          time: true,
        },
        battery: true,
        location: {
          id: true,
          name: true,
        },
        sensorId: true,
        measurement: true,
        updatedAt: true,
      },
    },
    { uuid: projectId },
    { pause: isEmpty(projectId) },
  );

  // Block navigation only when sensors list is empty, otherwise navigation inside NoSensors won't work
  useRouteChangeBlock(project?.sensors.length !== 0);

  const loading = useMemo(() => loadingSensors || loadingRule, [loadingSensors, loadingRule]);

  const [{ data: sensors }] = useQryProjectSensorsByRule(
    {
      id: true,
      name: true,
      unit: true,
      battery: true,
      sensorId: true,
      measurement: true,
      updatedAt: true,
      deviceId: true,
      ruleId: true,
      location: {
        id: true,
        name: true,
        createdAt: true,
        organization: {
          id: true,
          createdAt: true,
          email: true,
          language: true,
          name: true,
        },
        updatedAt: true,
      },
    },
    {
      data: {
        ruleId: ruleId ?? '',
        analysisId: alertId,
      },
    },
    { pause: !ruleId },
  );

  const [selectedSensors, setSelectedSensors] = useState<ProjectSensorWithRule[]>([]);

  useEffect(() => {
    if (sensors) {
      setSelectedSensors(sensors);
    }
  }, [sensors]);

  return (
    <DrawerLayout title={t('actions.Add rule')} onClose={requestClose}>
      {loading ? (
        <Loader />
      ) : (
        project && (
          <RuleForm
            alertId={alertId}
            project={project}
            rule={rule}
            selectedSensors={selectedSensors}
            onSubmit={requestClose}
          />
        )
      )}
    </DrawerLayout>
  );
};
