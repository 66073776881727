import { colors } from 'theme/colors';
import { MdClose } from 'react-icons/md';
import { Flex } from 'components/layout/Flex';
import { Label } from './Label';
import { SharedSelect, SharedSelectProps } from './SharedSelect';
import { Location } from 'types/location';

import { components } from 'react-select';

import type { OptionProps } from 'react-select';
import Spinner from 'components/Spinner';

type LocationSelectProps = Omit<SharedSelectProps<Location>, 'Option'> & {
  label?: string;
  selected?: Location[];
  onRemoveItem?: (item: Location) => void;
};

const Option = (props: OptionProps) => {
  const option = props.data as Location;
  return (
    option && (
      <components.Option {...props}>
        <span style={{ fontSize: '1.25rem' }}>{option.name}</span>
      </components.Option>
    )
  );
};

export const LocationSelect = ({
  onChange,
  label,
  placeholder,
  loadOptions,
  selected = [],
  onRemoveItem,
  onInputChange,
  loading,
  isMulti = true,
  onMenuClose,
  inputValue,
  ...props
}: LocationSelectProps) => {
  if (loading) return <Spinner />;
  return (
    <div>
      {label && <Label mb="0.25rem">{label}</Label>}

      <SharedSelect<Location>
        onChange={onChange}
        loadOptions={loadOptions}
        selected={selected}
        onInputChange={onInputChange}
        loading={loading}
        isMulti={isMulti}
        onMenuClose={onMenuClose}
        inputValue={inputValue}
        Option={Option}
        placeholder={placeholder}
        {...props}
      />

      <Flex flexDirection="column" alignItems="flex-start">
        {selected.map(
          (location, i) =>
            location && (
              <div
                key={i}
                style={{
                  display: 'flex',
                  backgroundColor: colors.brand,
                  justifyContent: 'space-between',
                  color: 'white',
                  padding: '0 0.5rem',
                  marginTop: '0.5rem',
                  fontSize: '1.25rem',
                  width: '34.5rem',
                }}
              >
                <span>{location.name}</span>
                {onRemoveItem && (
                  <button type="button" onClick={() => onRemoveItem(location)}>
                    <MdClose size="1rem" style={{ marginLeft: '0.625rem' }} />
                  </button>
                )}
              </div>
            ),
        )}
      </Flex>
    </div>
  );
};
