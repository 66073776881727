import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Checkbox } from 'components/form/Checkbox';
import { Input } from 'components/form/InputPure';
import { Label } from 'components/form/Label';
import { SelectInput } from 'components/form/SelectInput';
import { Box } from 'components/layout/Box';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { ObjectProperty, ObjectPropertyValue } from 'components/properties/ObjectProperty';
import { useSnackbar } from 'components/Snackbar';
import { Text } from 'components/typography';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import { AuthContext } from 'context/AuthContext';
import { useQryOrganization } from 'graphql/generated';
import { useAddDevice } from 'graphql/mutation/useAddDevice';
import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Severity } from 'state/snackbarStore';
import { colors } from 'theme/colors';
import {
  ConnectionType,
  elsecDeviceModels,
  ElsecDeviceModels,
  hanwellDeviceModels,
  HanwellDeviceModels,
  LascarElectronicsDeviceModels,
  lascarElectronicsDeviceModels,
  StandaloneDeviceTypes,
  standaloneDeviceTypes,
} from 'types/device';
import { CombinedError } from 'urql';
import { dispatchErrors } from 'utils/util';
import { z } from 'zod';

export const deviceNameInput = z
  .string()
  .min(1, 'Each device should contain a name')
  .max(255, 'The device name cannot exceed 255 characters');

const standaloneDeviceInput = z
  .object({
    deviceName: deviceNameInput,
    deviceType: z.nativeEnum(StandaloneDeviceTypes, {
      invalid_type_error: 'The device type should be a known type',
      required_error: 'The device type is required to determine the necessary configuration settings',
    }),
    deviceModel: z
      .union(
        [
          z.nativeEnum(ElsecDeviceModels),
          z.nativeEnum(HanwellDeviceModels),
          z.nativeEnum(LascarElectronicsDeviceModels),
          z.string().max(255),
        ],
        {
          invalid_type_error: 'The device model should be a known model',
        },
      )
      .optional(),
    locationId: z.string(),
    swapDevice: z.boolean(),
  })
  .refine((val) => val.swapDevice || val.locationId, {
    path: ['locationId'],
    message: 'The location is required to identify the device in the Charp network',
  });

type StandaloneDeviceInput = z.infer<typeof standaloneDeviceInput>;

type Props = {
  requestClose: (ignoreGuard?: boolean | undefined) => void;
  isGateway?: boolean;
  editDeviceLocation: (args: {
    deviceId: string;
    locationId: string;
  }) => Promise<{ data?: { editDeviceLocation: { name: string } }; error?: CombinedError }>;
  locationOptions: { label: string; value: string }[];
  locations: { id: string; name: string }[];
  onCreateLocation: (objectName: string) => Promise<ObjectPropertyValue>;
};

export const AddStandaloneDevice: FC<Props> = ({
  requestClose,
  isGateway,
  editDeviceLocation,
  locationOptions,
  locations,
  onCreateLocation,
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();
  const {
    watch,
    setValue,
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<StandaloneDeviceInput>({
    resolver: zodResolver(standaloneDeviceInput),
    defaultValues: {
      deviceName: '',
      deviceType: StandaloneDeviceTypes.ELSEC,
      deviceModel: '',
      locationId: '',
      swapDevice: false,
    },
  });
  const [{ data: org }] = useQryOrganization({ id: true }, {});
  const [swapDevice, setSwapDevice] = useState(false);
  const [, addDevice] = useAddDevice();

  const deviceType = watch('deviceType');
  const deviceModels = useMemo(() => {
    switch (deviceType) {
      case StandaloneDeviceTypes.ELSEC:
        return elsecDeviceModels;
      case StandaloneDeviceTypes.HANWELL:
        return hanwellDeviceModels;
      case StandaloneDeviceTypes.LASCAR_ELECTRONICS:
        return lascarElectronicsDeviceModels;
      default:
        return {};
    }
  }, [deviceType]);

  useEffect(() => {
    setValue('deviceModel', undefined);
    if (deviceType === StandaloneDeviceTypes.HANWELL) {
      setValue('deviceModel', HanwellDeviceModels.RL45);
    }
    if (deviceType === StandaloneDeviceTypes.ELSEC) {
      setValue('deviceModel', ElsecDeviceModels.ELSEC_765);
    }
  }, [deviceType, setValue]);

  const onSubmit: SubmitHandler<StandaloneDeviceInput> = useCallback(
    async (data) => {
      if (org) {
        const newDevice = await addDevice({
          organizationId: org.id,
          name: data.deviceName,
          connection: ConnectionType.STANDALONE,
          type: data.deviceType,
          model: data.deviceModel === '' ? undefined : data.deviceModel,
          isGateway,
        });
        const updatedDevice = await editDeviceLocation({
          deviceId: newDevice.data?.addDevice?.id ?? '',
          locationId: data.locationId,
        });
        if (updatedDevice.data) {
          snackbar.addAlert(
            t('Created new device: {{name}}', { name: updatedDevice.data.editDeviceLocation?.name }),
            Severity.SUCCESS,
          );
          requestClose();
        }
        if (updatedDevice.error) {
          dispatchErrors(snackbar, updatedDevice.error, authContext, t);
        }
      }
    },
    [addDevice, authContext, editDeviceLocation, isGateway, org, requestClose, snackbar, t],
  );

  const standaloneTypeOptions = useMemo(
    () =>
      Object.keys(standaloneDeviceTypes).map((type) => ({
        label: standaloneDeviceTypes[type as keyof typeof standaloneDeviceTypes],
        value: type,
      })),
    [],
  );

  const deviceModelsOptions = useMemo(
    () =>
      Object.keys(deviceModels).map((type) => ({
        label: deviceModels[type as keyof typeof deviceModels],
        value: type,
      })),
    [deviceModels],
  );

  return (
    <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} onSubmit={handleSubmit(onSubmit)}>
      <Body>
        <Box>
          <Label>{t('The device name')}*</Label>
          <Text variant="tiny" mb="0.25rem">
            {t('descriptions.Each device should have a name to quickly identify it throughout Charp')}
          </Text>
          <Input
            width="16.5rem"
            type="text"
            placeholder={t('placeholders.Enter the device name')}
            {...register('deviceName', {
              required: true,
            })}
          />
          <Box>{errors.deviceName && <ErrorMessage>{errors.deviceName.message}</ErrorMessage>}</Box>
        </Box>
        <Box mt="1.5rem">
          <Label>{t('The device type')}*</Label>
          <Text variant="tiny" mb="0.25rem">
            {t('descriptions.At Charp we support multiple device types, each for a specific use case')}
          </Text>

          <Controller
            name="deviceType"
            control={control}
            rules={{ required: true }}
            render={({ field: { value } }) => {
              return (
                <SelectInput
                  options={standaloneTypeOptions}
                  onChange={(selectedValue) =>
                    setValue('deviceType', selectedValue as StandaloneDeviceTypes, { shouldValidate: true })
                  }
                  placeholder={t('actions.Select a device type')}
                  value={standaloneTypeOptions.find((i) => i.value === value)}
                  width="16.5rem"
                />
              );
            }}
          />

          <Box>{errors.deviceType && <ErrorMessage>{errors.deviceType.message}</ErrorMessage>}</Box>
        </Box>

        <Box mt="1.5rem">
          <Label>{t('Location')}</Label>
          <Flex gap="0.5rem" mt="0.5rem" mb="0.5rem">
            <Checkbox
              checked={swapDevice}
              onChange={async () => {
                await setValue('swapDevice', !swapDevice);
                setSwapDevice(!swapDevice);
              }}
            />
            <Text variant="tiny" mb="0.25rem">
              {t('Use as swap device')}
            </Text>
          </Flex>
          <Controller
            name="locationId"
            control={control}
            rules={{ required: true }}
            render={({ field: { value } }) => {
              return (
                <ObjectProperty
                  title={t('Location')}
                  value={locationOptions.find((i) => i.value === value)}
                  values={locations ? locations.map((item) => ({ value: item.id, label: item.name })) : []}
                  onSave={async (selectedValue) =>
                    selectedValue ? await setValue('locationId', selectedValue) : undefined
                  }
                  onCreateObject={onCreateLocation}
                  style={{ width: '16.5rem', height: '2.7rem', border: `1px solid ${colors.brandBlue[600]}` }}
                />
              );
            }}
          />
          <Box>{errors.locationId && <ErrorMessage>{errors.locationId.message}</ErrorMessage>}</Box>
        </Box>
        {deviceType !== StandaloneDeviceTypes.OTHER && (
          <Box mt="1.5rem">
            <Label>{t('The device model')}</Label>
            <Text variant="tiny" mb="0.25rem">
              {t(
                'descriptions.The output of a device almost always differs from model to model. Although this field is optional, it helps us understand how we should analyze the output of a device',
              )}
            </Text>
            <Controller
              name="deviceModel"
              control={control}
              rules={{ required: true }}
              render={({ field: { value } }) => {
                return (
                  <SelectInput
                    options={deviceModelsOptions}
                    onChange={(selectedValue) => setValue('deviceModel', selectedValue, { shouldValidate: true })}
                    placeholder={t('actions.Select a device model')}
                    value={deviceModelsOptions.find((i) => i.value === value)}
                    width="16.5rem"
                  />
                );
              }}
            />
          </Box>
        )}
      </Body>
      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad onClick={() => requestClose()} variant="plain">
          {t('actions.Cancel')}
        </Button>
        <Button smallPad type="submit" loading={isSubmitting}>
          {t('actions.Save')}
        </Button>
      </DrawerFooter>
    </Form>
  );
};
