import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { FC, FormEvent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterStore } from 'state/filterStore';
import { TemperatureHumidityView, View } from 'graphql/generated';
import { Project } from 'types/project';
import { Sensor } from 'types/sensor';
import { isNonEmptyString } from 'utils/validation';
import shallow from 'zustand/shallow';
import { NoSensors } from './NoSensors';
import { Flex } from 'components/layout/Flex';
import { Label, LabelSmall } from 'components/form/Label';
import { ToggleButton } from 'components/form/ToggleButton';
import { LocationSelect } from 'components/form/LocationSelect';
import { ViewType } from 'types';
import { useProjectSensorLocation } from 'hooks/useProjectSensorLocation';
import { Measurement, SampleTime } from '@charphq/types';
import { Location } from 'types/location';
import { useProjectSensorLocationState } from 'hooks/useProjectSensorLocationState';
import { SelectInput } from 'components/form/SelectInput';
import { sampleTimeValues } from 'types/samplingTime';
import useIsMobile from 'hooks/useIsMobile';

type Props = {
  view?: TemperatureHumidityView;
  projectId: Project['id'];
  loading: boolean;
  onSave: (
    viewName: View['name'],
    sensorIds: Sensor['id'][],
    sampleTime?: SampleTime,
    autoAddNewDevices?: boolean,
  ) => void;
  saveLabel?: React.ReactNode;
  onClose: () => void;
  viewType: ViewType.DEW_POINT | ViewType.ABSOLUTE_HUMIDITY;
};

export const TemperatureHumidityViewForm: FC<Props> = ({
  view,
  projectId,
  loading,
  onSave,
  saveLabel = 'Save',
  onClose,
  viewType,
}) => {
  const { t } = useTranslation();
  const inputWidth = '16.5rem';

  const {
    name,
    setName,
    autoAddNewDevices,
    selectedLocations,
    setAutoAddNewDevices,
    projectSensorsBySelectedLocations,
    setSelectedLocations,
    dynamicLocationsByProjectSensors,
    sampleTime,
    setSampleTime,
  } = useProjectSensorLocationState({
    view,
    projectId,
    viewType,
  });

  useEffect(() => {
    if (view?.sampleTime) {
      setSampleTime(view.sampleTime as SampleTime);
    }
  }, [view, setSampleTime]);

  const isMobile = useIsMobile();

  const { loadingProjectSensors, noSensorsYet, handleSearch, setOffset, onRemoveLocation, loadLocationOptions } =
    useProjectSensorLocation({ projectId });

  const [clearFilters] = useFilterStore((state) => [state.projectSensorsForm.clear], shallow);

  const save = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      clearFilters();
      e.preventDefault();
      const projectSensorIds = projectSensorsBySelectedLocations
        ?.filter(
          (sensor) =>
            sensor?.sensor?.device?.locations.some((deviceLocation) =>
              selectedLocations.some((location) => location.id === deviceLocation.location.id),
            ) &&
            [Measurement.TEMPERATURE, Measurement.HUMIDITY].includes((sensor.sensor?.measurement as Measurement) ?? ''),
        )
        .map((sensor) => sensor.id);
      if (projectSensorIds && name) {
        onSave(name, projectSensorIds, sampleTime, autoAddNewDevices);
      }
    },
    [clearFilters, projectSensorsBySelectedLocations, name, selectedLocations, onSave, sampleTime, autoAddNewDevices],
  );

  const onAddLocations = (locations: Location[], setSelectedLocations: (locations: Location[]) => void) => {
    setSelectedLocations(locations);
  };

  const loadOptions = useCallback(
    () => loadLocationOptions(dynamicLocationsByProjectSensors),
    [loadLocationOptions, dynamicLocationsByProjectSensors],
  );

  const sampleTimeOptions = useMemo(() => {
    return Object.keys(sampleTimeValues).map((key) => {
      const value = sampleTimeValues[key as keyof typeof sampleTimeValues];
      return { value: key, label: value };
    });
  }, []);

  return (
    <Flex height="100%">
      {!loadingProjectSensors && noSensorsYet ? (
        <NoSensors projectId={projectId} onClick={onClose} />
      ) : (
        <Form onSubmit={save} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Body>
            <Input
              width="16.5rem"
              validate={isNonEmptyString}
              label={t('Name')}
              type="text"
              name="temperatureHumidityName"
              value={name}
              marginProps={{ mb: '1.5rem' }}
              placeholder={t('placeholders.Enter the view name')}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Flex mb="1.5rem" flexDirection="column">
              <Label mb="0.25rem">{t('Sampling interval')}</Label>
              <LabelSmall>{t('Divide data into time intervals, then average each interval')}</LabelSmall>
              <SelectInput
                options={sampleTimeOptions}
                onChange={(value) => setSampleTime(value as SampleTime)}
                value={
                  sampleTime && {
                    value: sampleTime ?? '',
                    label: sampleTimeOptions.find((option) => option.value === sampleTime)?.label ?? '',
                  }
                }
                placeholder={t('actions.Select a sampling interval')}
                width={isMobile ? '100%' : inputWidth}
              />
            </Flex>
            <Flex mb="1.5rem" flexDirection="column">
              <Label>{t('Auto add new devices')}</Label>
              <LabelSmall>{t('When you create a device will be automatically added to this view')}</LabelSmall>
              <ToggleButton value={autoAddNewDevices} onChange={() => setAutoAddNewDevices(!autoAddNewDevices)} />
            </Flex>
            <LocationSelect
              label={`${t('Add measurements by location')}*`}
              selected={selectedLocations}
              loading={loadingProjectSensors}
              onChange={(locations) => onAddLocations(locations as Location[], setSelectedLocations)}
              loadOptions={loadOptions}
              onRemoveItem={(locations) => onRemoveLocation(locations, setSelectedLocations, selectedLocations)}
              onInputChange={handleSearch}
              onMenuClose={() => setOffset(0)}
            />
          </Body>
          <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
            <Button smallPad onClick={onClose} variant="plain">
              {t('actions.Cancel')}
            </Button>
            <Button
              smallPad
              type="submit"
              loading={loading}
              disabled={!isNonEmptyString(name) || projectSensorsBySelectedLocations?.length === 0}
            >
              {saveLabel}
            </Button>
          </DrawerFooter>
        </Form>
      )}
    </Flex>
  );
};
