import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Input } from 'components/form/InputPure';
import { Label, LabelSmall } from 'components/form/Label';
import { RangeDatePicker } from 'components/form/RangeDatePicker';
import type { PeriodArray } from 'components/form/RangeDatePicker';
import { SelectInput } from 'components/form/SelectInput';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { Sensitivity } from 'graphql/generated';
import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilterStore } from 'state/filterStore';
import { Period } from 'types/period';
import { Project } from 'types/project';
import {
  LightBudgetView,
  roleOfColorConfigs,
  RoleOfColorType,
  roleOfColorValues,
  SensitivityCategory,
  sensitivityConfigs,
  SensitivityType,
  sensitivityValues,
  targetLifetimeConfigs,
  TargetLifetimeType,
  targetLifetimeValues,
  uvConfigs,
  UVType,
  uvValues,
} from 'types/view/lightBudgetView';
import { isNonEmptyString } from 'utils/validation';
import shallow from 'zustand/shallow';
import { NoSensors } from './NoSensors';
import { ToggleButton } from 'components/form/ToggleButton';
import { LocationSelect } from 'components/form/LocationSelect';
import { ViewType } from 'types';
import { useProjectSensorLocation } from 'hooks/useProjectSensorLocation';
import { Location } from 'types/location';
import { Measurement } from '@charphq/types';
import { useProjectSensorLocationState } from 'hooks/useProjectSensorLocationState';

export type LightBudgetViewData = {
  name: string;
  sensorIds: string[];
  period: Period;
  targetLifetime: number;
  sensitivity: Sensitivity;
  uv: boolean;
  roleOfColor: number;
  exposureTime: number;
  autoAddNewDevices?: boolean;
};

type Props = {
  view?: LightBudgetView;
  projectId: Project['id'];
  onSave: (data: LightBudgetViewData) => void;
  saveLabel: string;
  loading: boolean;
  onClose: () => void;
};

export const LightBudgetForm: FC<Props> = ({ projectId, onSave, saveLabel, loading, onClose, view }) => {
  const { t } = useTranslation();
  const [period, setPeriod] = useState<PeriodArray>([undefined, undefined]);
  const [targetLifetime, setTargetLifetime] = useState<number>(
    targetLifetimeConfigs[TargetLifetimeType.HIGH].value ?? 1,
  );
  const [roleOfColor, setRoleOfColor] = useState<number>(roleOfColorConfigs[RoleOfColorType.LARGER].value ?? 1);
  const [sensitivity, setSensitivity] = useState<Sensitivity>(sensitivityConfigs[SensitivityCategory.HIGH].value);
  const [sensitivitySelect, setSensitivitySelect] = useState<SensitivityType>(SensitivityCategory.HIGH);
  const [uv, setUV] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const {
    name,
    setName,
    autoAddNewDevices,
    selectedLocations,
    setAutoAddNewDevices,
    projectSensorsBySelectedLocations,
    setSelectedLocations,
    dynamicLocationsByProjectSensors,
  } = useProjectSensorLocationState({
    view,
    projectId,
    viewType: ViewType.LIGHT_BUDGET,
  });

  const {
    loadingProjectSensors,
    noSensorsYet,
    handleSearch,
    setOffset,
    onAddLocations,
    onRemoveLocation,
    loadLocationOptions,
  } = useProjectSensorLocation({ projectId });

  useEffect(() => {
    if (view !== undefined && !!view.id && !loadingSubmit) {
      setPeriod([new Date(view.period.start), view.period.end ? new Date(view.period.end) : undefined]);
      setTargetLifetime(view.controls[0].targetLifetime);
      setSensitivity(view.controls[0].sensitivity);
      setRoleOfColor(view.controls[0].roleOfColor);
      setUV(view.controls[0].uv);
    }
  }, [view]);

  const onTargetLifetimeSave = useCallback(
    async (value: TargetLifetimeType | string | null) => {
      if (value) {
        if (!isNaN(parseInt(value))) {
          setTargetLifetime(parseInt(value));
          return;
        }
        setTargetLifetime(targetLifetimeConfigs[value].value ?? 1);
      }
    },
    [setTargetLifetime],
  );

  const onSensitivityChange = useCallback((value: string) => {
    const newValue = value as SensitivityType;
    setSensitivitySelect(newValue);
    setSensitivity(sensitivityConfigs[newValue].value);
  }, []);

  const onRoleOfColorSave = useCallback(
    async (value: RoleOfColorType | string | null) => {
      if (value) {
        if (!isNaN(parseInt(value))) {
          setRoleOfColor(parseInt(value));

          return;
        }
        setRoleOfColor(roleOfColorConfigs[value].value ?? 1);
      }
    },
    [setRoleOfColor],
  );

  const onUVChange = useCallback((value: string) => {
    const newValue = value as UVType;
    setUV(uvConfigs[newValue].value);
  }, []);

  const [clearFilters] = useFilterStore((state) => [state.projectSensorsForm.clear], shallow);

  const formValid = useMemo(
    () => isNonEmptyString(name) && projectSensorsBySelectedLocations && projectSensorsBySelectedLocations.length > 0,
    [name, projectSensorsBySelectedLocations],
  );

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      clearFilters();
      setLoadingSubmit(true);
      const projectSensorIds =
        projectSensorsBySelectedLocations
          ?.filter(
            (sensor) =>
              sensor?.sensor?.device?.locations.some((deviceLocation) =>
                selectedLocations.some((location) => location.id === deviceLocation.location.id),
              ) && [Measurement.LIGHT].includes((sensor.sensor?.measurement as Measurement) ?? ''),
          )
          .map((sensor) => sensor.id) ?? [];
      if (formValid && projectSensorsBySelectedLocations && projectSensorsBySelectedLocations.length > 0) {
        const now = new Date();
        const lastWeekStart = new Date(now);
        lastWeekStart.setDate(now.getDate() - 7);
        onSave({
          name,
          sensorIds: projectSensorIds,
          period: {
            start: period[0]?.toISOString() ?? lastWeekStart.toISOString(),
            end: period[1]?.toISOString() ?? now.toISOString(),
          },
          targetLifetime,
          sensitivity,
          uv,
          roleOfColor,
          exposureTime: 100,
          autoAddNewDevices,
        });
      }
    },
    [
      clearFilters,
      formValid,
      name,
      onSave,
      roleOfColor,
      projectSensorsBySelectedLocations,
      sensitivity,
      period,
      targetLifetime,
      uv,
    ],
  );

  const targetLifetimeOptions = useMemo(
    () =>
      targetLifetimeValues.reduce<{ value: TargetLifetimeType; label: string }[]>((acc, curr) => {
        if (curr.type !== TargetLifetimeType.CUSTOM) {
          acc.push({ value: curr.type, label: t(curr.displayName) });
        }
        return acc;
      }, []),
    [t],
  );

  const targetLifetimeValue = useMemo<{ value: string; label: string }>(() => {
    if (targetLifetime) {
      const foundOption = targetLifetimeOptions.find((i) => {
        return targetLifetimeConfigs[i.value]?.value === targetLifetime;
      });

      if (foundOption) {
        return foundOption;
      }

      return {
        value: targetLifetime.toString(),
        label: t('{{count}} years', { count: targetLifetime }),
      };
    }
    return {
      value: '1',
      label: t('Default Label'),
    };
  }, [targetLifetimeOptions, targetLifetime, t]);

  const sensitivityOptions = useMemo(
    () => sensitivityValues.map((config) => ({ label: t(config.displayName), value: config.type })),
    [t],
  );

  const sensitivityValue = useMemo(
    () => sensitivityOptions.find((option) => option.value === sensitivitySelect.toString()),
    [sensitivitySelect, sensitivityOptions],
  );

  const uvOptions = useMemo(
    () => uvValues.map((config) => ({ label: t(config.displayName), value: config.type })),
    [t],
  );

  const uvValue = useMemo(() => {
    const uvType = uv ? UVType.PRESENT : UVType.REMOVED;
    return uvOptions.find((option) => option.value === uvType);
  }, [uv, uvOptions]);

  const roleOfColorOptions = useMemo(
    () =>
      roleOfColorValues.reduce<{ value: RoleOfColorType; label: string }[]>((acc, curr) => {
        if (curr.type !== RoleOfColorType.CUSTOM) {
          acc.push({ value: curr.type, label: t(curr.displayName) });
        }
        return acc;
      }, []),
    [t],
  );

  const roleOfColorValue = useMemo<{ value: string; label: string }>(() => {
    if (roleOfColor) {
      const foundOption = roleOfColorOptions.find((i) => {
        return roleOfColorConfigs[i.value]?.value === roleOfColor;
      });

      if (foundOption) {
        return foundOption;
      }

      return {
        value: roleOfColor.toString(),
        label: `${roleOfColor} JNDs`,
      };
    }
    return {
      value: '1',
      label: t('Default Label'),
    };
  }, [roleOfColorOptions, roleOfColor, t]);

  const loadOptions = useCallback(
    () => loadLocationOptions(dynamicLocationsByProjectSensors),
    [loadLocationOptions, dynamicLocationsByProjectSensors],
  );

  return (
    <Flex height="100%">
      {!loadingProjectSensors && noSensorsYet ? (
        <NoSensors projectId={projectId} onClick={onClose} />
      ) : (
        <Form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Body>
            <Flex mb="1.5rem" flexDirection="column" gap="0.25rem">
              <Label>{t('Name of view')}</Label>
              <Input
                required
                type="text"
                width="16.5rem"
                value={name}
                placeholder={t('placeholders.Enter the view name')}
                onChange={(event) => setName(event.target.value)}
              />
            </Flex>

            <Flex mb="1.5rem" flexDirection="column" gap="0.25rem">
              <Label>{t('Period')}</Label>
              <RangeDatePicker period={period} onChange={setPeriod} />
            </Flex>

            <Label>{t('Parameters')}</Label>
            <Flex gap="1.5rem" mb="1.5rem">
              <Flex flexDirection="column" gap="0.25rem" flex="1">
                <LabelSmall>{t('Target lifetime')}</LabelSmall>
                <SelectInput
                  options={targetLifetimeOptions}
                  onChange={onTargetLifetimeSave}
                  value={targetLifetimeValue}
                  placeholder={t('actions.Select a sampling interval')}
                  creatable
                  inputType="numeric"
                  formatCreateLabel={(v: string) => t('Set "{{years}}" years', { years: v })}
                  min={1}
                  max={9999}
                />
              </Flex>
              <Flex flexDirection="column" gap="0.25rem" flex="1">
                <LabelSmall>{t('Sensitivity')}</LabelSmall>
                <SelectInput
                  options={sensitivityOptions}
                  onChange={onSensitivityChange}
                  value={sensitivityValue}
                  placeholder={t('actions.Select a sampling interval')}
                />
              </Flex>
            </Flex>
            <Flex gap="1.5rem" mb="1.5rem">
              <Flex flexDirection="column" gap="0.25rem" flex="1">
                <LabelSmall>{t('UV')}</LabelSmall>
                <SelectInput
                  options={uvOptions}
                  onChange={onUVChange}
                  value={uvValue}
                  placeholder={t('actions.Select a sampling interval')}
                />
              </Flex>

              <Flex flexDirection="column" gap="0.25rem" flex="1">
                <LabelSmall>{t('Role of color')}</LabelSmall>
                <Flex flexDirection="row">
                  <SelectInput
                    options={roleOfColorOptions}
                    onChange={onRoleOfColorSave}
                    value={roleOfColorValue}
                    placeholder={t('actions.Select a sampling interval')}
                    formatCreateLabel={(v: string) => t('Set "{{jnds}}" JNDs', { jnds: v })}
                    inputType="numeric"
                    creatable
                    min={1}
                    max={99}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex mb="1.5rem" flexDirection="column">
              <Label>{t('Auto add new devices')}</Label>
              <LabelSmall>{t('When you create a device will be automatically added to this view')}</LabelSmall>
              <ToggleButton value={autoAddNewDevices} onChange={() => setAutoAddNewDevices(!autoAddNewDevices)} />
            </Flex>
            <LocationSelect
              label={`${t('Add measurements by location')}*`}
              selected={selectedLocations}
              loading={loadingProjectSensors}
              onChange={(locations) => onAddLocations(locations as Location[], setSelectedLocations, selectedLocations)}
              loadOptions={loadOptions}
              onRemoveItem={(locations) => onRemoveLocation(locations, setSelectedLocations, selectedLocations)}
              onInputChange={handleSearch}
              onMenuClose={() => setOffset(0)}
            />
          </Body>
          <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
            <Button smallPad onClick={onClose} variant="plain">
              {t('actions.Cancel')}
            </Button>
            <Button smallPad type="submit" disabled={!formValid} loading={loading}>
              {saveLabel}
            </Button>
          </DrawerFooter>
        </Form>
      )}
    </Flex>
  );
};
