import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { useSnackbar } from 'components/Snackbar';
import { Paragraph } from 'components/typography';
import { useAuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { UserContext } from 'context/UserContext';
import { ProjectNotificationTime, useQryGetProjectUserByProjectAndUser, useQryProject } from 'graphql/generated';
import { useEditProjectAlert } from 'graphql/mutation/useEditProjectAlert';
import { useEditProjectAlertCascadetime } from 'graphql/mutation/useEditProjectAlertCascadeTime';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FormEvent, useCallback, useContext, useEffect, useMemo, useState, VFC } from 'react';
import { Severity } from 'state/snackbarStore';
import { CascadeTime } from 'types/project';
import { ProjectUserRole } from 'types/projectUser';
import { dispatchErrors } from 'utils/util';
import { ControlOrderConfiguration } from '../configuration/cascadeOrder/cascadeOrderConfiguration';
import { ChannelsConfiguration } from '../configuration/channels/channelsConfiguration';
import { SelectInput } from 'components/form/SelectInput';
import { colors } from 'theme/colors';
import { ToggleButton } from 'components/form/ToggleButton';
import { Label, LabelSmall } from 'components/form/Label';
import { Form } from 'components/form';
import { Button } from 'components/buttons';
import { useEditProjectNotificationConfigurationValue } from 'server';
import { getProjectNotificationTimes } from 'types/projectNotificationConfiguration';
import { useEditProjectUserCommunicationChannels } from 'graphql/mutation/useEditProjectUserCommunicationChannels';
import { CommunicationChannel } from 'types/notification';
import { Loader } from 'components/loader/loader';
import { useEditProjectMaintenance } from 'graphql/mutation/useEditProjectMaintenance';
import { ProjectContext } from 'context/ProjectContext';
import { DateInput } from 'components/form/DateInput';
import styled from 'styled-components';
import Tooltip from 'components/tooltip/Tooltip';
import { isEmpty } from 'lodash';

type Props = DrawerComponentProps & { projectId: string };

const DateInputStyled = styled(DateInput)`
  color: ${(props) => props.theme.colors.base[600]};
  width: 9.5rem;
  cursor: pointer;
  &:focus {
    border-color: transparent !important;
  }
`;

export const NotificationConfigurationDrawer: VFC<Props> = ({ requestClose, projectId }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();
  const [{ data: project, fetching: loading }] = useQryProject(
    {
      id: true,
      name: true,
      alert: true,
      alertCascadeTime: true,
      alertCascadeOrder: true,
      maintenanceEndDate: true,
      users: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        phone: true,
        communicationChannel: true,
        createdAt: true,
        role: true,
        userId: true,
      },
      notifications: {
        id: true,
        type: true,
        status: true,
        message: true,
        createdAt: true,
        channel: true,
        user: {
          firstName: true,
          lastName: true,
        },
      },
      notificationConfigurations: {
        id: true,
        type: true,
        value: true,
      },
    },
    { uuid: projectId },
    { pause: isEmpty(projectId) },
  );
  const { addAlert } = useSnackbar();
  const [alert, setAlert] = useState<boolean>(false);
  const [maintenanceEndDate, setMaintenanceEndDate] = useState<Date | undefined>();
  const [alertCascadeTime, setCascadeTime] = useState<CascadeTime>();
  const [configurationValue, setConfigurationValue] = useState<ProjectNotificationTime | undefined>();
  const [channels, setChannels] = useState<CommunicationChannel[]>();

  const authContext = useAuthContext();
  const [, editProjectAlert] = useEditProjectAlert();
  const [, editProjectAlertCascadeTime] = useEditProjectAlertCascadetime();
  const [editProjectNotificationConfigurationValue] = useEditProjectNotificationConfigurationValue();
  const [, editProjectUserCommunicationChannels] = useEditProjectUserCommunicationChannels();

  const [user] = useContext(UserContext);
  const [{ data: projectUser }] = useQryGetProjectUserByProjectAndUser(
    {
      id: true,
      role: true,
      email: true,
      phone: true,
      userId: true,
      firstName: true,
      lastName: true,
      createdAt: true,
      communicationChannel: true,
    },
    { uuid: project?.id ?? '', userId: user?.id ?? '' },
    {
      pause: user?.id === undefined || project?.id === undefined,
    },
  );

  useEffect(() => {
    if (project) {
      const endDate = project.maintenanceEndDate && new Date(project.maintenanceEndDate);
      setAlert(project.alert);
      endDate && setMaintenanceEndDate(endDate);
      setCascadeTime(project.alertCascadeTime as CascadeTime);
      if (project.notificationConfigurations?.[0]) {
        setConfigurationValue(project.notificationConfigurations[0].value as ProjectNotificationTime);
      }
      if (projectUser) {
        setChannels(projectUser.communicationChannel as CommunicationChannel[]);
      }
    }
  }, [project, projectUser]);

  const isOwner =
    projectUser?.role === ProjectUserRole.enum.collaborator || projectUser?.role === ProjectUserRole.enum.owner;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (project) {
      if (project.alert !== alert) {
        await onAlertControl();
      }
      if (project.alertCascadeTime !== alertCascadeTime) {
        await onAlertCascadeTime();
      }
      if (project.notificationConfigurations?.[0]?.value !== (configurationValue as ProjectNotificationTime)) {
        await onConfigurationTime();
      }
      if (projectUser && channels) {
        if (
          projectUser.communicationChannel.some((element) => !channels.includes(element as CommunicationChannel)) ||
          channels.some((element) => !projectUser.communicationChannel.includes(element))
        ) {
          await onChannelsConfiguration();
        }
      }

      requestClose();
    }
  };

  const onAlertControl = useCallback(async () => {
    if (project) {
      const response = await editProjectAlert({ projectId: project.id, alert });
      if (response.data) {
        addAlert(t('Project alerts {{data}}', { data: alert ? t('enabled') : t('disabled') }), Severity.SUCCESS);
      } else if (response.error) {
        dispatchErrors({ addAlert }, response.error, authContext, t);
      }
    }
  }, [addAlert, authContext, editProjectAlert, project, alert, t]);

  const onAlertCascadeTime = useCallback(async () => {
    if (project && alertCascadeTime) {
      const response = await editProjectAlertCascadeTime({ projectId: project.id, alertCascadeTime });
      if (response.data) {
        addAlert(t('Project alert cascade time set to {{data}}', { data: alertCascadeTime }), Severity.SUCCESS);
      } else if (response.error) {
        dispatchErrors({ addAlert }, response.error, authContext, t);
      }
    }
  }, [addAlert, authContext, editProjectAlertCascadeTime, project, t, alertCascadeTime]);

  const onConfigurationTime = useCallback(async () => {
    if (project?.notificationConfigurations[0]) {
      await editProjectNotificationConfigurationValue({
        projectNotificationConfigurationId: project?.notificationConfigurations[0].id,
        value: configurationValue as ProjectNotificationTime,
      });
    }
  }, [project, configurationValue, editProjectNotificationConfigurationValue]);

  const onChannelsConfiguration = useCallback(async () => {
    if (projectUser && channels) {
      const response = await editProjectUserCommunicationChannels({
        projectUserId: projectUser.id,
        channels,
      });
      if (response.data) {
        addAlert(t('Updated communication'), Severity.SUCCESS);
      } else if (response.error) {
        dispatchErrors({ addAlert }, response.error, authContext, t);
      }
    }
  }, [projectUser, editProjectUserCommunicationChannels, channels, addAlert, t, authContext]);

  const cascadeTimeOptions = useMemo(
    () =>
      Object.values(CascadeTime).map((cascadeTime) => ({
        value: cascadeTime,
        label: cascadeTime,
      })),
    [],
  );

  const notificationTimeOptions = useMemo(
    () => getProjectNotificationTimes(t).map((time) => ({ value: time.value, label: time.key })),
    [t],
  );
  const { project: contextProject, loading: contextProjectLoading } = useContext(ProjectContext);
  const [{ fetching: updatingProject }, editProjectMaintenance] = useEditProjectMaintenance();
  const maintenanceSwitchDisabled =
    updatingProject || contextProjectLoading || (projectUser && projectUser.role !== ProjectUserRole.enum.owner);

  const onToggle = useCallback(
    async (date?: Date) => {
      if (!contextProject?.id) return;
      date && setMaintenanceEndDate(date);
      const editMaintenanceResult = await editProjectMaintenance({
        projectId: contextProject.id,
        maintenance: date ? true : !contextProject.maintenance,
        maintenanceEndDate: date,
      });
      if (editMaintenanceResult.data) {
        addAlert(t('alert_messages.Project maintenance updated'), Severity.SUCCESS);
      }
      if (editMaintenanceResult.error) {
        dispatchErrors({ addAlert }, editMaintenanceResult.error, authContext, t);
      }
    },
    [contextProject, editProjectMaintenance, addAlert, authContext, t],
  );

  return (
    <DrawerLayout title={t('Notification Settings')} onClose={requestClose}>
      <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} onSubmit={onSubmit}>
        <Body>
          {loading && <Loader />}
          {project && (
            <>
              <Paragraph fontSize="1.125rem" color={colors.primary[500]} mb="0.25rem">
                {t('Project').toUpperCase()}
              </Paragraph>
              <Paragraph fontSize="1.125rem" color={colors.base[600]} mb="1.5rem">
                {t('This settings applies to all project members')}
              </Paragraph>

              <Flex alignItems="center" gap="0.5rem" mb="1.5rem">
                <ToggleButton
                  disabled={!isOwner}
                  value={alert}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAlert(event.target.checked)}
                />
                <Label>{t('Enable Alert Notification')}</Label>
              </Flex>
              <Flex flexDirection="column" mb="1.5rem" gap="0.25rem">
                <Label>{t('Alert Cascade')}</Label>
                <LabelSmall>{t('Timing')}</LabelSmall>
                <SelectInput
                  options={cascadeTimeOptions}
                  value={cascadeTimeOptions.find((time) => time.value === alertCascadeTime)}
                  onChange={(value) => setCascadeTime(value as CascadeTime)}
                  placeholder={t('placeholders.Select a time')}
                  width="9.5rem"
                />
              </Flex>
              <Flex flexWrap="wrap">
                <ControlOrderConfiguration isOwner={isOwner} project={project} />
              </Flex>
              <Flex gap="0.5rem" flexDirection="column" mb="1.5rem">
                <Label>{t('Pause notifications')}</Label>
                <LabelSmall>
                  {t('Turn this on during maintenance to temporarily stop notifications via email and SMS.')}
                </LabelSmall>
                <LabelSmall>{t('End date')}</LabelSmall>
                <Flex alignItems="center" gap="1.5rem" data-tooltip-id="leftArrow">
                  <DateInputStyled
                    allowPastDates={false}
                    value={maintenanceEndDate}
                    onChange={(date) => onToggle(date)}
                    width="9.5rem"
                  />
                  <ToggleButton
                    disabled={maintenanceSwitchDisabled || !contextProject?.maintenance}
                    value={contextProject?.maintenance ?? false}
                    onChange={() => onToggle()}
                  />
                </Flex>
                {!contextProject?.maintenance && (
                  <Tooltip
                    id="leftArrow"
                    place="right"
                    content={t('First, select an end date for the maintenance period.')}
                  />
                )}
              </Flex>
              {projectUser && (
                <ChannelsConfiguration projectUser={projectUser} values={channels} onChange={setChannels} />
              )}
              <Flex gap="0.25rem" mb="1.5rem" flexDirection="column">
                <Label>{t('Notification Digest')}</Label>
                <LabelSmall>{t('Timing')} </LabelSmall>
                <SelectInput
                  options={notificationTimeOptions}
                  value={notificationTimeOptions.find((time) => time.value === configurationValue)}
                  onChange={(value) => setConfigurationValue(value as ProjectNotificationTime)}
                  placeholder={t('placeholders.Select a time')}
                  width="9.5rem"
                />
              </Flex>
            </>
          )}
        </Body>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad variant="plain" onClick={() => requestClose()}>
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit">
            {t('actions.Save')}
          </Button>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
