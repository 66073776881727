import { useCallback } from 'react';
import { useFilterStore } from 'state/filterStore';
import shallow from 'zustand/shallow';

interface Paginated<T> {
  count?: number;
  items?: T[];
}

export const useLoadOptionsAndSearch = <T extends { id: string }>(selectedSensors: T[]) => {
  const [setFilter] = useFilterStore((state) => [state.projectSensorsForm.filter], shallow);

  const loadOptions = useCallback(
    async (
      sensorPages: Paginated<T> | undefined,
      offset: number,
      limit: number,
      setOffset: (offset: number) => void,
    ): Promise<{ options: T[]; hasMore: boolean }> => {
      if (!sensorPages) {
        return {
          options: [],
          hasMore: false,
        };
      }

      const pageCount = sensorPages.count ?? 0;
      const totalPageCount = Math.ceil(pageCount / limit);
      const currentPage = Math.floor(offset / limit) + 1;

      const hasMore = currentPage < totalPageCount;

      if (hasMore) {
        setOffset(currentPage * limit);
      }

      const selectedSensorIds = new Set(selectedSensors.map((sensor) => sensor.id));
      const sensorsWithoutSelected = sensorPages.items?.filter((sensor) => !selectedSensorIds.has(sensor.id)) || [];

      return {
        options: sensorsWithoutSelected,
        hasMore,
      };
    },
    [selectedSensors],
  );

  const handleSearch = (search: string) => {
    setFilter({ name: search });
  };

  return {
    loadOptions,
    handleSearch,
  };
};
