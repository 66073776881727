import { css } from '@styled-system/css';
import { Button } from 'components/buttons';
import { Input } from 'components/form/Input';
import { SelectInput } from 'components/form/SelectInput';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { useSnackbar } from 'components/Snackbar';
import { useAuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { languageOptions } from 'context/LocaleContext';
import { useEditUserInput } from 'graphql/mutation/useEditUserInput';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Severity } from 'state/snackbarStore';
import styled from 'styled-components';
import { User } from 'types/user';
import { dispatchErrors } from 'utils/util';

const phoneValidator = (phone: string): boolean => {
  const regEx = /^\+[1-9]\d{10,14}$/;
  return regEx.test(phone);
};

const Error = styled.span(
  css({
    color: 'danger',
  }),
);

type Props = DrawerComponentProps & {
  user: User;
};

export const ProfileEditDrawer: FC<Props> = ({ user, requestClose }) => {
  const { t, i18n } = useTranslation();
  useRouteChangeBlock();
  const authContext = useAuthContext();
  const [phone, setPhone] = useState<string>(user.phone ?? '');
  const [language, setLanguage] = useState(user.language || 'en');
  const [valid, setValid] = useState<boolean>(true);
  const { addAlert } = useSnackbar();
  const [{ fetching: loading }, editUserInput] = useEditUserInput();

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValid(true);
    setPhone(event.target.value);
  }, []);

  const onSubmit = useCallback(async () => {
    if (phone && !phoneValidator(phone)) {
      setValid(false);
      return;
    } else {
      const editResponse = await editUserInput({ data: { language, phone } });
      if (editResponse.data?.editUser) {
        addAlert(t('User profile updated'), Severity.SUCCESS);
      } else if (editResponse.error) {
        dispatchErrors({ addAlert }, editResponse.error, authContext, t);
      }
      requestClose();
    }
  }, [addAlert, authContext, editUserInput, phone, language, requestClose, t]);

  const handleLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const onClose = () => {
    requestClose();
    i18n.changeLanguage(user.language);
  };

  return (
    <DrawerLayout title={t('actions.Edit profile')} onClose={onClose}>
      <Body>
        <Flex flexDirection="column" marginBottom="1.5rem">
          <Input
            value={phone}
            onChange={onChange}
            placeholder={t('placeholders.Enter your phone number')}
            label={t('Phone number')}
            width="16.5rem"
          />
          {!valid && phone && (
            <Error>{`${t('errors.Invalid phone number')}: [+][${t('errors.country code')}][${t(
              'errors.phone number including area code',
            )}]`}</Error>
          )}
        </Flex>
        <Flex flexDirection="column">
          <SelectInput
            label={t('Language')}
            options={languageOptions}
            onChange={handleLanguage}
            value={languageOptions.find((lang) => lang.value === language)}
            placeholder={t('Select language')}
            width="16.5rem"
          />
        </Flex>
      </Body>
      <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
        <Button smallPad onClick={onClose} variant="plain">
          {t('actions.Cancel')}
        </Button>
        <Button smallPad type="submit" disabled={loading} loading={loading} onClick={onSubmit}>
          {t('actions.Save')}
        </Button>
      </DrawerFooter>
    </DrawerLayout>
  );
};
