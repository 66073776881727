import { User } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_USER_KEY = 'editUser';

const EDIT_USER = `
  mutation ($data: UpdateUserInput!) {
    ${EDIT_USER_KEY}(data: $data) {
      id
      phone
      language
    }
  }
`;

type MutVarEditUser = {
  data: {
    phone?: string; // Optional, as per your new mutation definition
    language: string;
  };
};

type MutResEditUser = DefaultQueryResponse<typeof EDIT_USER_KEY, Pick<User, 'id' | 'phone' | 'language'>>;

export const useEditUserInput = () => {
  return useMutation<MutResEditUser, MutVarEditUser>(EDIT_USER);
};
